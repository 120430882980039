import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import WebViewer from '@pdftron/pdfjs-express';


@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('viewer', { static: false }) viewer: ElementRef;
  wvInstance: any;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngAfterViewInit(): void {
    console.log('activatedRoute', this.activatedRoute)
    const pages = this.activatedRoute.snapshot.paramMap.get('pages')
    console.log('pages', pages)


    WebViewer({
      path: '../lib',
      initialDoc: `https://d4s-space.fra1.cdn.digitaloceanspaces.com/pdf-demo/webviewer-demo-annotated-${pages}.pdf`,
    }, this.viewer.nativeElement).then(instance => {
      instance.openElements(['loadingModal']);
      this.wvInstance = instance;
      const annotManager = instance.annotManager;
      const docViewer = instance.docViewer;

      instance.setHeaderItems(function (header) {
        console.log('header', header.getHeader('toolbarGroup-Shapes'))

        header.getHeader('toolbarGroup-Shapes').delete(11);
        header.getHeader('toolbarGroup-Shapes').delete(11);
      })

      // const utils = new ExpressUtils({
      //   serverKey: 'your_server_key',
      //   clientKey: 'your_client_key'
      // });

      // instance.setHeaderItems((header) => {
      //   header.push({
      //     type: 'actionButton',
      //     img: '',
      //     onClick: async () => {
      //       const xfdf = await annotManager.exportAnnotations({ links: false, widgets: false });
      //       const fileData = await docViewer.getDocument().getFileData({});
      //       const resp = await utils.setFile(fileData).setXFDF(xfdf).merge();
      //       const mergedBlob = await resp.getBlob();
      //       saveAs(mergedBlob, 'myfile.pdf')
      //       await resp.deleteFile();
      //     }
      //   });
      // });

      // now you can access APIs through this.webviewer.getInstance()
      // instance.openElements(['notesPanel']);
      // see https://www.pdftron.com/documentation/web/guides/ui/apis for the full list of APIs

      // or listen to events from the viewer element
      this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
        const [pageNumber] = e.detail;
        console.log(`Current page is ${pageNumber}`);
      });

      // or from the docViewer instance
      instance.docViewer.on('annotationsLoaded', () => {
        console.log('annotations loaded');
      });

      instance.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler)
    })
  }

  ngOnInit() {

    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
  }

  async wvDocumentLoadedHandler() {
    // you can access docViewer object for low-level APIs

    const annotManager = this.wvInstance.annotManager;
    const LayoutMode = this.wvInstance.LayoutMode;
    this.wvInstance.setLayoutMode(LayoutMode.Continuous);





    this.wvInstance.closeElements(['loadingModal']);

    // and access classes defined in the WebViewer iframe
    // const { Annotations } = this.wvInstance;
    // const rectangle = new Annotations.RectangleAnnotation();
    // rectangle.PageNumber = 1;
    // rectangle.X = 100;
    // rectangle.Y = 100;
    // rectangle.Width = 250;
    // rectangle.Height = 250;
    // rectangle.StrokeThickness = 5;
    // rectangle.Author = annotManager.getCurrentUser();
    // annotManager.addAnnotation(rectangle);
    // annotManager.drawAnnotations(rectangle.PageNumber);
    // see https://www.pdftron.com/api/web/WebViewer.html for the full list of low-level APIs


  }


  async click() {
    const documentViewer = this.wvInstance.docViewer;
    const documentStream = await documentViewer.getDocument().getFileData({});
    const documentBlob = new Blob([documentStream], { type: 'application/pdf' });

    // Example function that saves a blob to the server
    this.downloadFile(documentBlob, 'test.pdf');
  }


  downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    // setTimeout(() => URL.revokeObjectURL(link.href), 1000);
  };

}
